import React from "react";
import { Line } from "react-chartjs-2";
import { getLast100Puzzles } from "../integrations/APIClient.js";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import "chartjs-adapter-moment";

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

const MultiAxisLineChart = ({ dates, ratings }) => {
  // Assuming `dates` is an array of date strings and `ratings` is an array of rating numbers

  // Data for the chart
  const data = {
    labels: dates, // Use dates for labels
    datasets: [
      {
        label: "Ratings Over Time",
        data: ratings,
        fill: false,
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 3,
        yAxisID: "y",
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Rating Progression Over Time",
      },
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "MMM D YYYY",
        },
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Rating",
        },
      },
    },
  };

  getLast100Puzzles("6562115612a17a46ade08bbd").then((data) => {
    console.log(data);
  });
  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default MultiAxisLineChart;
