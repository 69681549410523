const Success = () => {
  return (
    <div className="col success">
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase.</p>
    </div>
  );
};

export default Success;
