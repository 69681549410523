import React from "react";

const ChessCamps = () => {
  return (
    <div className="chess-camps col">
      <h1>Chess Camps 2024</h1>
      <h2>Join Us for Chess Camps this Summer, October, and Christmas</h2>
      <div className="camp-details">
        <h3>Summer Chess Camp</h3>
        <p>Dates: July 1, 2024 - August 31, 2024</p>
        <p>Time: 9:00 AM - 3:00 PM</p>

        <h3>October Chess Camp</h3>
        <p>Dates: October 21, 2024 - October 25, 2024</p>
        <p>Time: 9:00 AM - 3:00 PM</p>

        <h3>Christmas Chess Camp</h3>
        <p>Dates: December 20, 2024 - December 24, 2024</p>
        <p>Time: 9:00 AM - 3:00 PM</p>
      </div>
      <div className="about">
        <h3>About Our Camps</h3>
        <p>
          Our chess camps provide an excellent opportunity for young players to
          improve their skills, learn new strategies, and enjoy the game of
          chess in a fun and supportive environment. Whether you're a beginner
          or an advanced player, our camps are designed to help you take your
          game to the next level.
        </p>
      </div>
      <div className="coach">
        <h3>Meet the Coach</h3>
        <p>
          Our camps are led by Lukasz Piecha, a FIDE chess school instructor and
          the chief coach of Hampshire Junior Chess Association. Lukasz brings a
          wealth of experience and a passion for teaching chess to young
          players.
        </p>
      </div>
      <div className="contact">
        <h3>Contact Us</h3>
        <p>If you have any questions, feel free to reach out to us:</p>
        <p>
          Email:{" "}
          <a href="mailto:chesshampshire@gmail.com">chesshampshire@gmail.com</a>
        </p>
      </div>
      <div className="location">
        <h3>Location</h3>
        <p>Freemantle and Shirley Community Centre</p>
        <p>Randolph Street, Southampton, SO15 3HE, UK</p>
      </div>
    </div>
  );
};

export default ChessCamps;
