import React, { useEffect, useRef } from "react";

function StripeBuyButton() {
  const buttonContainerRef = useRef(null); // Reference for the button container
  const scriptLoadedRef = useRef(false); // Flag to track if the script has been loaded
  const buttonCreatedRef = useRef(false); // Flag to track if the button has been created

  useEffect(() => {
    // Function to load the Stripe script only once
    const loadStripeScript = () => {
      if (scriptLoadedRef.current) {
        // If the script is already loaded, just create the button if not created
        if (!buttonCreatedRef.current) {
          createStripeButton();
        }
        return;
      }

      // Create a script element to load Stripe's JS library
      const scriptElement = document.createElement("script");
      scriptElement.src = "https://js.stripe.com/v3/buy-button.js";
      scriptElement.async = true;

      // Set script loaded flag to true upon successful load
      scriptElement.onload = () => {
        scriptLoadedRef.current = true;
        if (!buttonCreatedRef.current) {
          createStripeButton(); // Create the button after the script is loaded
        }
      };

      // Silently handle script loading errors without displaying them
      scriptElement.onerror = () => {
        console.error("Stripe script failed to load.");
      };

      // Append the script element to the document body
      document.body.appendChild(scriptElement);
    };

    loadStripeScript(); // Invoke the function to load the Stripe script

    // Cleanup function: Component will unmount, but the script stays loaded
    return () => {
      // Cleanup actions if needed
    };
  }, []); // Run this effect only once when the component mounts

  // Function to create the Stripe Buy Button element
  const createStripeButton = () => {
    if (buttonCreatedRef.current || !scriptLoadedRef.current) {
      return; // Prevent button creation if it has already been created or the script is not loaded
    }

    if (buttonContainerRef.current) {
      buttonContainerRef.current.innerHTML = ""; // Clear previous content to prevent duplication

      // Create the Stripe Buy Button element
      const stripeBuyButton = document.createElement("stripe-buy-button");
      stripeBuyButton.setAttribute(
        "buy-button-id",
        "buy_btn_1Pz3EnEqx1V56brjSmcJGA1a"
      );
      stripeBuyButton.setAttribute(
        "publishable-key",
        "pk_live_51OqwYREqx1V56brjlfCmzkffqzBWBBgyREU6ur6n5Y6p15LsbSkYKJUpn0xhW48ggEZwrGJ4lWEX8pC0Mtu5fImj00neIwVyEw"
      );

      buttonContainerRef.current.appendChild(stripeBuyButton);

      buttonCreatedRef.current = true; // Mark button as created
    }
  };

  return (
    <div className="stripe-buy-button-container">
      <div className="col">
        <h2>Winchester Junior Chess Club</h2>
        <p>
          At the Winchester Junior Chess Club, children can learn and play chess
          in a fun and engaging environment. An experienced tutor will share
          their knowledge, guiding young players and students who want to
          improve their chess skills.
        </p>
      </div>
      <div ref={buttonContainerRef}></div> {/* Container for Stripe button */}
    </div>
  );
}

export default StripeBuyButton;
