import React from "react";

const FreemantleShirleyChessClub = () => {
  return (
    <div className="freemantle-shirley-chess-club col">
      <h1>Freemantle and Shirley Community Centre Chess Club</h1>
      <h2>Chess Activities on Thursdays</h2>
      <div className="details">
        <p>
          <strong>Date and Time:</strong> Every Thursday, 4:30 PM - 6:30 PM
          (Holidays included)
        </p>
        <p>
          <strong>Location:</strong> Freemantle and Shirley Community Centre,
          Randolph Street, Southampton, SO15 3HE, UK
        </p>
      </div>
      <div className="about">
        <h3>About This Event</h3>
        <p>
          Join us for weekly chess activities every Thursday from 4:30 PM to
          6:30 PM. Whether you're a beginner learning the basics or an advanced
          player honing your strategies, these sessions offer an opportunity for
          players of all levels to improve their skills and enjoy the game of
          chess.
        </p>
        <img
          src="/sothamptonchess.png"
          alt="freemantle-shirley-chess-club"
          className="imgshirley"
        />
      </div>
      <div className="contact">
        <h3>Contact Us</h3>
        <p>If you have any questions, feel free to reach out to us:</p>
        <p>
          Email:{" "}
          <a href="mailto:chesshampshire@gmail.com">chesshampshire@gmail.com</a>
        </p>
      </div>
      <div className="location">
        <h3>Location</h3>
        <p>Freemantle and Shirley Community Centre</p>
        <p>Randolph Street, Southampton, SO15 3HE, UK</p>
      </div>
    </div>
  );
};

export default FreemantleShirleyChessClub;
