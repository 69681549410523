import React, { useState, useEffect } from "react";
import Chessground from "@react-chess/chessground";
import { Chess } from "chess.js";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";
import "chessground/assets/chessground.cburnett.css";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const HintWithAlerts = ({
  showHint,
  fen = "8/6k1/3K2b1/8/8/1Q6/8/8 w - - 0 1",
  after_pgn = ["d6e6", "g6f7"],
  orientation,
}) => {
  const [game, setGame] = useState(new Chess(fen));
  const [currentMove, setCurrentMove] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const moves = Array.isArray(after_pgn) ? after_pgn : after_pgn.split(" ");

  useEffect(() => {
    // Create a new instance of the game based on the initial fen
    const newGame = new Chess(fen);
    // Apply each move up to the current move
    moves.slice(0, currentMove).forEach((move) => {
      newGame.move(move, { sloppy: true });
    });
    // Update the game state
    setGame(newGame);

    // Optionally show an alert for the current move
    if (currentMove > 0) {
      setAlertMessage(`Move ${currentMove}: ${moves[currentMove - 1]}`);
      setShowAlert(true);
    } else {
      setShowAlert(false); // No move to show
    }
  }, [currentMove, fen]); // Removed `moves` and `initialFen` as dependencies to avoid infinite loops

  const handleForward = () => {
    if (currentMove < moves.length) {
      setCurrentMove((prevMove) => prevMove + 1);
    }
  };

  const handleBackward = () => {
    if (currentMove > 0) {
      setCurrentMove((prevMove) => prevMove - 1);
    }
  };

  return (
    <div className="hints" style={{ display: showHint ? "block" : "none" }}>
      {showAlert && (
        <Alert variant="info" onClose={() => setShowAlert(false)} dismissible>
          {alertMessage}
        </Alert>
      )}
      <Button
        variant="secondary"
        onClick={handleBackward}
        disabled={currentMove === 0}
      >
        ←
      </Button>{" "}
      <Button
        variant="primary"
        onClick={handleForward}
        disabled={currentMove === moves.length}
      >
        →
      </Button>
      {/* <div className="lichess-learn">
        <iframe
          src="https://lichess.org/learn#/"
          title="Lichess Learn"
          width="800"
          height="600"
          frameBorder="0" // Use frameBorder="0" to remove the border around the iframe.
          allowFullScreen // This attribute enables the iframe to be displayed in full screen.
        ></iframe>
      </div> */}
      <Chessground config={{ fen: game.fen(), orientation }} />
    </div>
  );
};

export default HintWithAlerts;
