// import { Chessground as Chessground} from 'chessground';

// const config = {};
// const ground = Chessground(document.body, config);

// ground.getKeyAtDomPos()

const sparePieces = [
  "P",
  "R",
  "N",
  "B",
  "Q",
  "K",
  "p",
  "r",
  "n",
  "b",
  "q",
  "k",
];

import React, { useEffect, useRef, useState } from "react";
import { Chessground as ChessgroundApi } from "chessground";

function Test({
  width = 900,
  height = 900,
  config = {},
  contained = false,
  onPieceDrop = (piece, square) => {},
}) {
  const [api, setApi] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current && !api) {
      const chessgroundApi = ChessgroundApi(ref.current, {
        animation: { enabled: true, duration: 200 },
        ...config,
      });
      setApi(chessgroundApi);
    } else if (ref && ref.current && api) {
      api.set(config);
    }
  }, [ref]);

  useEffect(() => {
    api?.set(config);
  }, [api, config]);

  return (
    <>
      <div
        style={{
          height: contained ? "100%" : height,
          width: contained ? "100%" : width,
        }}
      >
        <div
          ref={ref}
          style={{ height: "100%", width: "100%", display: "table" }}
        />

        <div className="spare-pieces">
          {sparePieces.map((piece) => (
            <div
              key={piece}
              className={`piece ${piece}`}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("text/plain", piece);
                e.dataTransfer.setData("source", "spare");
              }}
              onDragEnd={(e) => {
                const square = api.getKeyAtDomPos([e.clientX, e.clientY]);

                onPieceDrop(piece, square);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Test;
