import React from "react";
import ScheduleTable from "./ScheduleTable";

const WinchesterChessClub = () => {
  return (
    <div className="winchester-chess-club col">
      <h1>Winchester Junior Chess Club</h1>
      <h2>Chess Mastery Awaits</h2>
      <div className="description">
        <p>
          The beautiful thing about learning is that nobody can take it away
          from you. Dive into the world of chess and unlock the grandmaster
          within you. Every move on the board teaches you something new,
          presenting challenges that sharpen your mind and spirit. Remember,
          every great chess player was once a beginner. Embrace patience,
          strategy, and foresight. Let your journey to mastery be a testament to
          your dedication and passion for chess. Your potential is unlimited,
          and every game is a new opportunity to surpass your previous limits.
          Stand ready at the board, for it's not just a game, but a gateway to
          discovering your true prowess. Take the next step, make your move, and
          let's embark on this adventure together. Your chess story is just
          beginning, and the path to greatness is yours to claim.
        </p>
        <img
          src="/adamcamp.png"
          alt="winchester-chess-club"
          className="img-landing"
        />
      </div>
      <div className="schedule">
        <h3>Schedule</h3>
        <ScheduleTable
          dates={["1/09/24", "8/09/24", "15/09/24", "29/09/24"]}
          hours="11am - 1pm"
        />
      </div>
      <div className="contact">
        <h3>Contact Us</h3>
        <p>If you have any questions, feel free to reach out to us:</p>
        <p>
          Email:{" "}
          <a href="mailto:Chesshampshire@gmail.com">Chesshampshire@gmail.com</a>
        </p>
        <p>
          Phone: <a href="tel:00447711804361">0044 7711804361</a>
        </p>
      </div>
      <div className="location">
        <h3>Location</h3>
        <p>Badger Farm Community Centre</p>
        <p>2 Badger Farm Road, Winchester, SO22 4QB</p>
      </div>
    </div>
  );
};

export default WinchesterChessClub;
