import React from "react";
import Chessground from "@react-chess/chessground";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Login from "../components/Login";
import { Button } from "react-bootstrap";
import ChessClub from "../components/ChessClub";
import WinchesterChessClub from "../components/WinchesterChessClub";
import LichessTeamBattleInfo from "../components/LichessTeamBattleInfo";
import OnlineMeetings from "../components/OnlineMeetings";
import LandingPage from "../components/LandingPage";
import CookieConsent from "../components/CookieConsent";
function Home() {
  const [config, setConfig] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const chessConfig = {
    fen: "rnbqk1nr/1ppp1ppp/p7/2b1p2Q/2B1P3/8/PPPP1PPP/RNB1K1NR w KQkq - 0 4",
    // Add other config options as needed
  };
  const toggleLoginModal = () => setShowLogin(!showLogin);
  return (
    <div className="home">
      <CookieConsent />
      <LandingPage />
      <ChessClub />
      <WinchesterChessClub />
      <LichessTeamBattleInfo />
      <OnlineMeetings />
      <header className="App-header col">
        {" "}
        <h2>Chess AI Teacher </h2>
        <p>Your guide to improving your chess skills</p>
        <Login showLogin={showLogin} handleClose={toggleLoginModal} />
      </header>
      <Row>
        <Col sm={12} xl={7} className="ai col">
          <div>
            <h1>AI: The Game Changer in Chess Training</h1>
            <h3>
              New to chess or already a pro? Our Chess Trainer AI has got you
              covered. Start from scratch or level up your game, personalized
              training for everyone.
            </h3>
            <ul>
              <li className="iconhome1">Personalized training for everyone</li>
              <li className="iconhome2">
                Designed to guide your chess journey
              </li>
              <li className="iconhome3">
                Chess trainers have used this tool to achieve great results
              </li>
              <li className="iconhome4">Learn play chess from scrutch.</li>
              <li className="iconhome5">Gain Elo points quickly</li>
              <li className="iconhome6">
                Improve by about 100 Elo points a week
              </li>
              <li className="iconhome7">Try it for free for 7 days</li>
              <li className="iconhome8">
                £4.99 per month after 7 days of trial
              </li>
              <li className="iconhome8">
                You can use this site 14 days for free in total
              </li>
            </ul>
            <div className="homeai">
              Artificial Intelligence, or AI, is the "GAME CHANGER" in the
              entire world. The AI trainer on{" "}
              <a href="https://Chesshood.com">Chesshood.com</a> provides
              personalized training for everyone. It doesn't matter if you've
              played chess all your life or just heard about the game. This
              platform is designed to guide your chess journey.
            </div>

            <p>
              Chess trainers have used this tool to achieve great results. If
              you want to quickly gain chess Elo points, or improve by about 100
              Elo points a week (and later, maybe a bit slower 😉), then this is
              the perfect place to do it. You can enjoy this site free of charge
              for a total of 14 days, and after that, there's a symbolic price
              of £4.99 per month at this moment.
            </p>
            <h2>Getting Started</h2>
            <p>
              To receive personalized training, you need to be registered and
              logged in. <br></br>
              <Button variant="primary" onClick={toggleLoginModal}>
                Login to start
              </Button>{" "}
              <br></br>Once you start, you just need to solve a chess problem,
              also called a chess PUZZLE.
            </p>
            <h2>What You Will Learn</h2>
            <p>
              You will receive starting Elo points, and the chess bot trainer
              Chesshood will analyze your results to provide you with chess
              positions. Chess has a lot of categories like: Opening,
              Middlegame, or Endgame, also called stages of the game. Moreover,
              the chess world is filled with motifs like: Checkmate in one move,
              Check, Double Check, Discovery Check, Double Attack, Pin, etc.
            </p>
            <p>
              Every puzzle solved affects your ratings. Solve them correctly to
              gain points and improve your Elo. Struggling with a position? Use
              a hint. While hints can lead to a loss of Elo points, they
              sometimes offer additional insights and rewards. Think of each
              hint as a strategic draw in your ongoing chess match against the
              board.
            </p>
            <h2>Track Your Progress</h2>
            <p>
              Always check your statistics to know how many positions you solved
              correctly, how many mistakes and hints you used. Your history of
              solved positions is also available for review, ensuring you can
              learn from past challenges.
            </p>
            <h2>The Power of Training</h2>
            <p>
              The key point is TRAINING. Here lies all the power of chess
              skills, tactics, and some strategy for your improvement. Let’s
              START...
            </p>
          </div>

          <h3 className="homequotation">
            <h1>"</h1> Embark on a chess journey with AI Chesshood.
            <br></br> Let AI guide you to quick and easy improvement. <br></br>
            Explore categories, solve puzzles effortlessly, and witness
            progress. <br></br> Set goals, and the machine adapts. <br></br>{" "}
            Enjoy a seamless chess-learning experience! <br></br>
            <br></br>
            <h1>"</h1>
          </h3>
          <section className="App-section">
            <h2>Welcome to Chess AI Teacher</h2>
            <p>
              Enhance your chess strategy and tactics with our AI-powered chess
              trainer. Navigate through various categories of puzzles, each
              designed to challenge and improve specific aspects of your
              gameplay.
            </p>
          </section>
          <h2 className="hometitle">Welcome to Chesshood</h2>
          <p>
            This site utilizes and reorganizes chess puzzles from the free
            software Lichess.org, as well as puzzles provided by chess trainers
            and tutors.
          </p>
          <p>
            Our AI teacher searches through more than 3.8 million puzzles across
            a vast range of categories to find the perfect positions to aid your
            improvement.
          </p>
          <p>
            The AI teacher bases its research on your achievements and mistakes,
            as well as on your rating and progress.
          </p>
          <h2>The goal of your AI trainer aligns with your chess goals.</h2>

          <script async src="https://js.stripe.com/v3/buy-button.js"></script>

          <stripe-buy-button
            buy-button-id="buy_btn_1OqxaOEqx1V56brjdOcp1DDM"
            publishable-key=""
          ></stripe-buy-button>
        </Col>
        <Col sm={12} xl={4}>
          <div className="bot">
            <img src="bota.png" alt="chess" className="homeimg " />
          </div>
          <div className="col imgcolhome">
            <img src="bot44.png" alt="chess" className="homeimg " />
          </div>
          <div className="col imgcolhome">
            <img src="tuto2.png" alt="chess" className="homeimg " />
          </div>
          <div className="col imgcolhome">
            <img src="bot33.png" alt="chess" className="homeimg " />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Chessground
            contained={false}
            className="img-fluid ml-5 homeboard"
            config={chessConfig}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Home;
