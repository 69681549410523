// chessUtils.js
import { Chess } from "chess.js";

// Assuming SQUARES is provided by 'chess.js' or define it accordingly
export const SQUARES = [
  // Define SQUARES if needed, or import it from 'chess.js' if available
];

export const game = new Chess();

export function toDests(chess) {
  const dests = new Map();
  SQUARES.forEach((s) => {
    const ms = chess.moves({ square: s, verbose: true });
    if (ms.length)
      dests.set(
        s,
        ms.map((m) => m.to)
      );
  });
  return dests;
}

export function toColor(chess) {
  return chess.turn() === "w" ? "white" : "black";
}

export function toPgnHistory(chess) {
  let pgn = "";
  chess.history({ verbose: true }).forEach((move) => {
    pgn += move.from + move.to;
    if (move.promotion) {
      pgn += move.promotion;
    }
    pgn += " ";
  });
  return pgn.trim();
}
