const Cancel = () => {
  return (
    <div className="cancel col">
      <h1>Payment Canceled</h1>
      <p>
        Your payment was canceled. Please try again if you wish to complete the
        purchase.
      </p>
    </div>
  );
};

export default Cancel;
