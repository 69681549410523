import Chessground from "@react-chess/chessground";
import { Chess, SQUARES } from "chess.js";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";
import "chessground/assets/chessground.cburnett.css";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AfterPgn } from "../integrations/AfterPgnEdit";
import Test from "./Test";

import {
  getUser,
  getPuzzle,
  getFirstPuzzle,
  savePuzzle,
} from "../integrations/APIClient";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AfterPgnEdit from "../integrations/AfterPgnEdit";

const myStyle = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

const myStyle2 = {
  width: "500px",
  height: "500px",
  // position: 'relative',
  display: "block",
};

const game = new Chess();
const sparePieces = [
  "P",
  "R",
  "N",
  "B",
  "Q",
  "K",
  "p",
  "r",
  "n",
  "b",
  "q",
  "k",
];

const handleDropOnBoard = (e) => {
  const piece = e.dataTransfer.getData("text/plain");
  // Further logic to find the destination square and place the piece
  // This is a starting point; you might need more logic
};

function toColor(chess) {
  return chess.turn() === "w" ? "white" : "black";
}

function toPgnHistory(chess) {
  let pgn = "";
  chess.history({ verbose: true }).forEach((move) => {
    pgn += move.from + move.to + " ";
  });
  return pgn.trim();
}
function handleDrop(event) {
  console.log("game.fen(): " + event);
}

export default function Edit() {
  const [gameFen, setGameFen] = useState("8/8/8/8/8/8/8/8");

  const puzzleID = useParams().id;
  const puzzle = useRef(null);
  const [moveNr, setMoveNr] = useState(-1);
  const [config, setConfig] = useState({});

  const [category, setCategory] = useState(
    puzzle.current ? puzzle.current.category : ""
  );
  const [fenPosition, setfenPosition] = useState(
    puzzle.current ? puzzle.current.fenPosition : ""
  );
  const [description, setDescription] = useState(
    puzzle.current ? puzzle.current.description : ""
  );
  const [successMessage, setSuccessMessage] = useState(
    puzzle.current ? puzzle.current.success_message : ""
  );
  const [failMessage, setFailMessage] = useState(
    puzzle.current ? puzzle.current.fail_message : ""
  );
  const [rating, setRating] = useState(
    puzzle.current ? puzzle.current.rating : ""
  );
  const [after_pgn, setAfter_pgn] = useState(
    puzzle.current ? puzzle.current.after_pgn : ""
  );

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    if (puzzle.current) {
      console.log(
        "category",
        puzzle.current.category + " " + event.target.value
      );
      puzzle.current.category = event.target.value;
    }
  };

  const handlefenPositionChange = (event) => {
    try {
      game.load(event.target.value);
      setfenPosition(event.target.value);
      updateGame(game.fen());

      if (puzzle.current) {
        puzzle.current.fenPosition = event.target.value;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    if (puzzle.current) {
      puzzle.current.description = event.target.value;
    }
  };

  const handleSuccessMessageChange = (event) => {
    setSuccessMessage(event.target.value);
    if (puzzle.current) {
      puzzle.current.success_message = event.target.value;
    }
  };

  const handleAfter_pgnChange = (event) => {
    console.log("after_pgnChange:", event.target.value);

    setAfter_pgn(event.target.value);
    if (puzzle.current) {
      puzzle.current.after_pgn = event.target.value;
    }
  };

  const handleFailMessageChange = (event) => {
    setFailMessage(event.target.value);
    if (puzzle.current) {
      puzzle.current.fail_message = event.target.value;
    }
  };

  const handleRatingChange = (event) => {
    setRating(event.target.value);
    if (puzzle.current) {
      puzzle.current.rating = event.target.value;
    }
  };

  function updateGame(fen) {
    // console.log("game.pgn(): ", game.fen());
    const newConfig = {
      fen: fen,
      orientation:
        puzzle.current.fenPosition.split(" ")[1] === "w" ? "black" : "white",
      turnColor: toColor(game),
      sparePieces: true,

      trashPieces: true,
      //  movable: {

      // color: toColor(game),
      // dests: toDests(game),
      events: {
        move: (orig, dest, metadata) => {
          game.put(game.get(orig), dest);
          game.remove(orig);
          updateGame(game.fen());
        },
      },
      highlight: {
        lastMove: false,
      },
    };

    setConfig(newConfig);
  }

  function moveColour(moveNr) {
    console.log("userMove");
    const computerColour = config.orientation === "black" ? "white" : "black";
    return moveNr % 2 ? config.orientation : computerColour;
  }

  function SparePiece({ piece }) {
    return (
      <div
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData("piece", piece);
        }}
      >
        {piece}
      </div>
    );
  }
  function setMove(moveNr) {
    game.load(puzzle.current.fenPosition);
    console.log("game.pgn(): ", game.fen());
    for (let i = 0; i <= moveNr; i++) {
      game.move(puzzle.current.after_pgn[i]);
    }
    updateGame(game.fen());
    setMoveNr(moveNr);
  }
  function displayCoordinates(event) {
    console.log(`(${event.clientX}, ${event.clientY})`);
  }

  document.addEventListener("click", displayCoordinates);
  useEffect(() => {
    if (puzzleID) {
      getPuzzle(puzzleID).then((data) => {
        window.history.pushState("", "", "/edit/" + data["_id"]["$oid"]);

        puzzle.current = data;

        puzzle.current.after_pgn = puzzle.current.after_pgn;
        game.load(puzzle.current.fenPosition);
        setConfig({ orientation: game.turn() === "b" ? "white" : "black" });
        updateGame(puzzle.current.fenPosition);

        setCategory(puzzle.current.category);
        setfenPosition(puzzle.current.fenPosition);
        setDescription(puzzle.current.description);
        setSuccessMessage(puzzle.current.success_message);
        setFailMessage(puzzle.current.fail_message);
        setAfter_pgn(puzzle.current.after_pgn);
        setRating(puzzle.current.rating);
        setGameFen(puzzle.current.fenPosition);
      });
    } else {
      getFirstPuzzle().then((data) => {
        window.history.pushState("", "", "/edit/" + data["_id"]["$oid"]);
        puzzle.current = data;
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx", data.after_pgn);
        puzzle.current.after_pgn = puzzle.current.after_pgn;
        setGameFen(puzzle.current.fenPosition);
        game.load(puzzle.current.fenPosition);
        setConfig({ orientation: game.turn() === "b" ? "white" : "black" });
        console.log("---", game.turn());
        updateGame(puzzle.current.fenPosition);
      });
    }
  }, []);

  function userMove(orig, dest, metadata) {
    const pgn = orig.concat(dest);
    // game.move(pgn);
    console.log("userMove", pgn);
    updateGame(game.fen());
  }
  const onDrop = () => {
    const piece = e.dataTransfer.getData("piece");

    console.log("move", mov, e);

    // see if the move is legal
    let move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q", // always promote to a queen for example simplicity
    });
  };

  async function updateFen() {
    const pieceElements = document.querySelectorAll("piece");

    game.clear();

    // console.log("pieceElements", pieceElements[31].cgKey, pieceElements[31].cgPiece);
    for (let i = 0; i < pieceElements.length; i++) {
      console.log(
        "pieceElements",
        pieceElements[i].cgKey,
        pieceElements[i].cgPiece
      );

      const initials = pieceElements[0].cgPiece;

      console.log("initials", initials[1], initials[0], "---------------"); // Outputs 'br'

      game.put(
        { type: initials[1], color: initials[0] },
        pieceElements[i].cgKey
      );

      console.log("game.fen()", game.fen());
    }
    console.log("game.fen()", game.fen());
  }

  async function setupAfterPgn() {
    console.log("setupAfterPgn");
    const pieceElements = document.querySelectorAll("piece");

    // console.log("pieceElements", pieceElements[31].cgKey, pieceElements[31].cgPiece);
    for (let i = 0; i < pieceElements.length; i++) {
      if (typeof initials === "undefined" && setupAfterPgn === "undefined") {
        console.log(
          "pieceElements",
          pieceElements[i].cgKey,
          pieceElements[i].cgPiece
        );

        const initials = pieceElements[i].cgPiece
          .split(" ")
          .map((word) => word[0])
          .join("");

        console.log("initials", initials[1], initials[0], "---------------");

        console.log(
          "Game HISTORY: ",
          game.history({ verbose: true }),
          "---------------"
        );
      }
      console.log("game.fen()", game.fen());
    }
  }

  async function updatePuzzle() {
    {
      console.log("description: " + description);
    }
    const updatedPuzzle = {
      id: puzzleID, // Replace this with the actual puzzle ID
      category: category,
      rating: rating,
      fenPosition: game.fen(),
      description: description,
      success_message: successMessage,
      fail_message: failMessage,
      after_pgn: after_pgn,
    };
    savePuzzle(puzzleID, updatedPuzzle);
  }
  async function saveAsNew() {
    {
      console.log("description: " + description);
    }
    const updatedPuzzle = {
      category: category,
      rating: rating,
      fenPosition: game.fen(),
      description: description,
      success_message: successMessage,
      fail_message: failMessage,
      after_pgn: after_pgn,
    };
    savePuzzle("new", updatedPuzzle).then((data) => {
      if (data.id) {
        window.location.replace("/edit/" + data.id);
      }
    });
  }

  function nextPuzzle() {
    console.log("edit next puzzle");
    window.location.replace("/edit/");
  }
  return (
    <Row>
      <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
        <div
          className="boardeditor"
          // onDrop={handleDropOnBoard}
          // onDragOver={(e) => e.preventDefault()} // This allows for drops
        >
          <Test
            config={config}
            onPieceDrop={(piece, square) => {
              let color = piece === piece.toUpperCase() ? "w" : "b";
              game.put({ type: piece.toUpperCase(), color: color }, square);
              // console.log(game.fen());
              console.log(config);
              updateGame(game.fen());
            }}
          />

          {}
          {/* </Test> */}
        </div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="starting-fen" onClick={() => setMove(-1)}>
            Starting FEN
          </InputGroup.Text>
          <Form.Control
            value={game.fen()}
            onChange={handlefenPositionChange}
            aria-label="Small"
            aria-describedby="starting-fen"
          />
        </InputGroup>

        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="after_pgn">after_pgn</InputGroup.Text>
          <Form.Control
            value={
              puzzle.current && puzzle.current.after_pgn != null
                ? puzzle.current.after_pgn
                : ""
            }
            onChange={handleAfter_pgnChange}
            aria-label="Small"
            aria-describedby="after_pgn"
          />
        </InputGroup>

        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="description">Description</InputGroup.Text>
          <Form.Control
            value={puzzle.current ? puzzle.current.description : ""}
            onChange={handleDescriptionChange}
            aria-label="Small"
            aria-describedby="description"
          />
        </InputGroup>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="categories">Chess Categories </InputGroup.Text>
          <Form.Control
            value={puzzle.current ? puzzle.current.category : ""}
            onChange={handleCategoryChange}
            aria-label="Small"
            aria-describedby="categories"
          />
        </InputGroup>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="success">Success</InputGroup.Text>
          <Form.Control
            value={puzzle.current ? puzzle.current.success_message : ""}
            onChange={handleSuccessMessageChange}
            aria-label="Small"
            aria-describedby="success"
          />
        </InputGroup>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="failure">Failure</InputGroup.Text>
          <Form.Control
            value={
              puzzle.current &&
              (puzzle.current.failMessage || puzzle.current.fail_message)
                ? puzzle.current.failMessage || puzzle.current.fail_message
                : ""
            }
            onChange={handleFailMessageChange}
            aria-label="Small"
            aria-describedby="failure"
          />
        </InputGroup>
        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text id="rating">Rating</InputGroup.Text>
          <Form.Control
            value={rating}
            onChange={handleRatingChange}
            aria-label="Small"
            aria-describedby="rating"
          />
        </InputGroup>

        <Button variant="success" onClick={setupAfterPgn}>
          Setup success movess
        </Button>
        <Button variant="primary" onClick={updatePuzzle}>
          Update Puzzle
        </Button>
        <a className="btn btn-success" onClick={nextPuzzle} type="button">
          next
        </a>
        <Button variant="secondary" onClick={saveAsNew}>
          Save as new
        </Button>
        <AfterPgnEdit initialFen={game.fen()} />
      </Col>
    </Row>
  );
}
