const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7071"
    : "https://chesshood.com";

// Helper function for error handling
function handleResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      throw new Error(text || response.statusText);
    });
  }
  return response.json();
}

// Function to set up headers for fetch requests
export function authHeader(headers = new Headers()) {
  headers.append("Content-Type", "application/json");
  // Add other headers like authorization tokens if needed
  return headers;
}

// Fetch user details
export async function getUser() {
  try {
    const response = await fetch(new URL("/api/user", BASE_URL).href, {
      headers: authHeader(),
      // credentials: "include", // Uncomment if you need to include cookies
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
}

// Update user details
export async function updateUser(userDetails) {
  try {
    const response = await fetch(new URL("/api/user", BASE_URL).href, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(userDetails),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
}

// Fetch the first puzzle
export async function getFirstPuzzle() {
  try {
    const response = await fetch(new URL("/api/training", BASE_URL).href, {
      headers: authHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching the first puzzle:", error);
    throw error;
  }
}

// Fetch a specific puzzle by ID or category
export async function getPuzzle(puzzleid, category) {
  try {
    const url = new URL(`/api/puzzles/${puzzleid ? puzzleid : ""}`, BASE_URL);
    if (category) {
      url.searchParams.append("category", category);
    }
    const response = await fetch(url.href, {
      headers: authHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching puzzle:", error);
    throw error;
  }
}

// Fetch user successes
export async function getUserSuccesses() {
  try {
    const response = await fetch(
      new URL("/api/user/successes", BASE_URL).href,
      {
        headers: authHeader(),
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching user successes:", error);
    throw error;
  }
}

// Fetch user failures by category
export async function getUserFailures(category) {
  try {
    const url = new URL(
      `/api/user/failures/${encodeURIComponent(category)}`,
      BASE_URL
    );
    const response = await fetch(url.href, {
      headers: authHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching user failures:", error);
    throw error;
  }
}

// Fetch total user failures
export async function getTotalUserFailures() {
  try {
    const response = await fetch(new URL("/api/user/failures", BASE_URL).href, {
      headers: authHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching total user failures:", error);
    throw error;
  }
}

// Fetch total user hints
export async function getTotalUserHints() {
  try {
    const response = await fetch(
      new URL("/api/user/hints_count", BASE_URL).href,
      {
        headers: authHeader(),
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching total user hints:", error);
    throw error;
  }
}

// Save a puzzle
export async function savePuzzle(puzzleid, updatedPuzzle) {
  try {
    const response = await fetch(
      new URL(`/api/edit/${puzzleid}`, BASE_URL).href,
      {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(updatedPuzzle),
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Error saving puzzle:", error);
    throw error;
  }
}

// Fetch puzzles by category
export async function getPuzzlesByCategory(category, page, size) {
  try {
    const url = new URL(`/api/puzzles/getByCategory/${category}`, BASE_URL);
    url.searchParams.append("page", page);
    url.searchParams.append("size", size);

    const response = await fetch(url.href, {
      headers: authHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching puzzles by category:", error);
    throw error;
  }
}

// Check puzzle result
export async function checkPuzzleResult(puzzleid, pgn, hint) {
  try {
    const response = await fetch(
      new URL(`/api/puzzles/${puzzleid}/check`, BASE_URL).href,
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ pgn, hint }),
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Error checking puzzle result:", error);
    throw error;
  }
}

// Fetch last 100 puzzles for a user
export async function getLast100Puzzles(userId) {
  try {
    const url = new URL(
      `/api/user/${encodeURIComponent(userId)}/last100puzzles`,
      BASE_URL
    );
    const response = await fetch(url.href, {
      headers: authHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching last 100 puzzles:", error);
    throw error;
  }
}
