import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import Login from "../components/Login"; // Ensure this path is correct

import { getUser, updateUser } from "../integrations/APIClient";
import { validate } from "../integrations/user";

function Profile() {
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    getUser().then((data) => setUser(data));
  }, []);

  // Toggle visibility of the login modal
  const toggleLoginModal = () => setShowLogin(!showLogin);

  if (!user) {
    return (
      <div>
        <Container>
          <Row className="align-items-center" style={{ marginTop: "20px" }}>
            <Col ms={12} md={6}>
              <h1>Welcome to Chess Trainer AI</h1>
              <h3>
                To get your player profile, and get your elo points and start to
                get personalized training, you will need to sign in. Our
                platform offers you a tailored experience to improve your chess
                skills, whether you're just starting out or looking to polish
                your tactics and strategy.
              </h3>
              <Button variant="primary" onClick={toggleLoginModal}>
                Login to start
              </Button>
            </Col>
            <Col ms={12} md={6}>
              <img
                src="tuto2.png"
                alt="Chess Strategies"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>

        {/* Login modal */}
        <Login showLogin={showLogin} handleClose={toggleLoginModal} />
      </div>
    );
  }

  return (
    <div>
      <h1>User Profile</h1>

      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label column sm="2">
          Email
        </Form.Label>
        <Col sm="10">
          <Form.Control plaintext readOnly defaultValue={user.email} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="firstname">
        <Form.Label column sm="2">
          First Name
        </Form.Label>
        <Col sm="10">
          <Form.Control
            value={user?.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            isInvalid={validate(user).includes("firstname")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="lastname">
        <Form.Label column sm="2">
          Last Name
        </Form.Label>
        <Col sm="10">
          <Form.Control
            value={user?.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            isInvalid={validate(user).includes("lastname")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="nickname">
        <Form.Label column sm="2">
          Nick Name
        </Form.Label>
        <Col sm="10">
          <Form.Control
            value={user?.nickName}
            onChange={(e) => setUser({ ...user, nickName: e.target.value })}
            isInvalid={validate(user).includes("nickname")}
          />
        </Col>
      </Form.Group>
      <Button onClick={() => updateUser(user)}>Save</Button>
    </div>
  );
}

export default Profile;
