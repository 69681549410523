import React from "react";
import { Alert } from "react-bootstrap";
import Hint from "../pages/Hint";
import { Row, Col } from "react-bootstrap";

const CustomAlert = ({
  variant,
  onClose,
  user,
  addedOrRestRating,
  puzzleSuccessMessage,
  success,
  fen,
  after_pgn,
  config,
}) => {
  // Ensure user object and its properties are safely accessed
  const userRating = user ? user.rating : "No rating available";
  // Assuming user.category_rating is an object where the category is the key and the rating is the value
  const categoryRatings =
    user && user.category_rating ? user.category_rating : {};

  // Function to render content based on the success status
  const renderContent = () => {
    if (success === "true") {
      return (
        <>
          <img src="/happy.webp" alt="happy" className="happy" />
          <h4 className="alert-heading">Correct move! Well done!</h4>
        </>
      );
    } else if (success === "hint") {
      return (
        <>
          {/* <p>{after_pgn}</p> */}
          <Hint
            className="hint hintboard"
            showHint={"showHint"}
            after_pgn={after_pgn}
            fen={fen}
            config={config}
            orientation={"black"}
          />
        </>
      );
    } else {
      return (
        <>
          <img src="/idea4.webp" alt="sad" className="happy" />
          <h4 className="alert-heading">Wrong Answer!</h4>
        </>
      );
    }
  };

  return (
    <Alert variant={variant} onClose={onClose} dismissible>
      <Row>
        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
          {renderContent()}
          <p>{puzzleSuccessMessage}</p>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
          <p>
            Your rating: <strong>{userRating}</strong>
          </p>
          {/* Example for safely rendering optional properties */}
          <div className="addedOrRestRating">
            Rating Change: <strong>{addedOrRestRating || "No change"}</strong>
          </div>

          <p className="categorieshint">
            <ul className="scrollable-list">
              {Object.entries(categoryRatings)
                .sort((a, b) => a[1] - b[1]) // Sorting the array in ascending order based on rating
                .map(([category, rating], index) => (
                  <li key={index}>
                    {category} - {rating}
                  </li>
                ))}
            </ul>
          </p>
        </Col>
      </Row>
    </Alert>
  );
};

export default CustomAlert;
