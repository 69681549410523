import { React } from "react";
import { Col } from "react-bootstrap";

// Assuming config is passed as a prop to this component
const ChessMoveIndicator = ({ config }) => {
  return (
    <span className="turnplay">
      <img src="/bota.png" alt="logo" className="bota" />{" "}
      <h3
        className={`turnis ${
          config.orientation === "white" ? "White" : "Black"
        }`}
      >
        {config.orientation === "white" ? "White" : "Black"} to move
      </h3>
    </span>
  );
};

export default ChessMoveIndicator;
