import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faHome,
  faSignOutAlt,
  faChess,
} from "@fortawesome/free-solid-svg-icons";
import Login from "../components/Login";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7071"
    : "https://chesshood.com";

const SideNav = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/user`);
        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setUser(null);
      }
    };

    fetchUser();
  }, []);

  const handleLoginToggle = () =>
    setShowLogin((prevShowLogin) => !prevShowLogin);

  return (
    <>
      <div className="menu-left p-3">
        <Navbar collapseOnSelect expand="xl" className="navbarhood">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="flex-column">
              <Row>
                <Col>
                  <div className="text-center logoportrait">
                    <img src="/logo.png" alt="logo" className="logo" />
                    <span className="text-center logo-text">Chesshood</span>
                  </div>
                </Col>
              </Row>
              <Button
                variant="dark"
                className="btn btn-dark btn-lg bottonfold btn-block"
                href="/"
              >
                <FontAwesomeIcon icon={faHome} /> Home
              </Button>
              <Button
                variant="dark"
                className="btn btn-dark btn-lg bottonfold btn-block"
                href={user ? "/Play" : "/Play"}
              >
                <FontAwesomeIcon icon={faChess} /> Training
              </Button>
              <Button
                variant="dark"
                className="btn btn-dark btn-lg bottonfold btn-block"
                href="/Stats"
              >
                <FontAwesomeIcon icon={faChess} /> Statistics
              </Button>
              <Button
                variant="dark"
                href="/Calendar"
                className="btn btn-dark btn-lg bottonfold btn-block"
              >
                <FontAwesomeIcon icon={faChess} /> Calendar
              </Button>
              <Button
                variant="dark"
                href="/Profile"
                className="btn btn-dark btn-lg bottonfold btn-block"
              >
                <FontAwesomeIcon icon={faChess} /> Profile
              </Button>
              <Button
                variant="dark"
                href="/Checkout"
                className="btn btn-dark btn-lg bottonfold btn-block"
              >
                <FontAwesomeIcon icon={faChess} /> Subscription
              </Button>
              {user ? (
                <Button
                  className="btn btn-dark btn-lg bottonfold btn-block"
                  href={
                    process.env.NODE_ENV === "development"
                      ? "http://localhost:7071/api/dev_logout?post_logout_redirect_uri=http://localhost:3000/"
                      : "https://chesshood.com/.auth/logout?post_logout_redirect_uri=https://chesshood.com/"
                  }
                >
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </Button>
              ) : (
                <Button
                  className="btn btn-dark btn-lg bottonfold btn-block"
                  onClick={handleLoginToggle}
                >
                  Login
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <Login showLogin={showLogin} handleClose={handleLoginToggle} />
    </>
  );
};

export default SideNav;
