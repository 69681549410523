import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import {
  GoogleLoginButton,
  FacebookLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";

const EncourageLogin = () => {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh", color: "white" }}
    >
      <Row className="text-center">
        <Col>
          <h1 className="display-4 mb-4">Welcome to ChessHood!</h1>
          <p className="lead mb-4">
            Login to start your personalized training and improve your chess
            skills.
          </p>
          <GoogleLoginButton
            onClick={() =>
              (window.location.href =
                "https://chesshood.com/.auth/login/google?post_login_redirect_uri=https://chesshood.com/play")
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EncourageLogin;
