// Login.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  GoogleLoginButton,
  FacebookLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";

function Login({ showLogin, handleClose }) {
  return (
    <Modal className="login" show={showLogin} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Please login to ChessHood</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>To be able to follow your improvements, you need to log in.</p>
        <GoogleLoginButton
          onClick={() =>
            (window.location.href =
              "https://chesshood.com/.auth/login/google?post_login_redirect_uri=https://chesshood.com/play")
          }
        />
        <img src="/bota.png" alt="logo" className="bota" />{" "}
        {/* <FacebookLoginButton
          onClick={() =>
            (window.location.href =
              "https://chesshood.com/.auth/login/facebook?post_login_redirect_uri=https://chesshood.com/play")
          }
        /> */}
        {/* <AppleLoginButton
          onClick={() =>
            (window.location.href =
              "https://chesshood.com/.auth/login/apple?post_login_redirect_uri=https://chesshood.com/play")
          }
        /> */}
        {process.env.NODE_ENV === "development" && (
          <Button
            className="btn btn-dark btn-lg bottonfold btn-block"
            href="http://localhost:7071/api/dev_login?post_login_redirect_uri=http://localhost:3000/play"
          >
            Dev login
          </Button>
        )}
        <br></br>
        <a href="/PrivacyPolicy.pdf">Privacy Policy</a>
        <br></br>
        <a href="/TermsofServiceforChesshood.pdf">
          Terms of Service for Chesshood
        </a>
        <br></br>
        <a href="/support.pdf">support</a>
        <br></br>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Login;
