import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const backgroundColorPlugin = {
  id: "customCanvasBackgroundColor",
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = options.color || ""; // Corrected default color
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

function StatisticsChart({ data }) {
  const chartData = {
    labels: ["Accepted", "With Hint", "Failed"],
    datasets: [
      {
        data: data,
        backgroundColor: [
          "#4CAF50", // Green
          "#9E9E9E", // Gray
          "#F44336", // Red
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      animateRotate: false,
      animateScale: true,
    },
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Current Allocations",
      },
      backgroundColorPlugin, // Ensure this plugin is correctly utilized
    },
  };

  return (
    <div className="piecat">
      <Pie
        key={Date.now()}
        data={chartData}
        options={options}
        plugins={[backgroundColorPlugin]}
      />
    </div>
  );
}

export default StatisticsChart;
