import React from "react";

const ContactPage = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>If you have any questions, feel free to reach out to us:</p>
      <p>
        Email: <a href="Chesshampshire@gmail.com">Chesshampshire@gmail.com</a>
      </p>
      <p>
        Phone: <a href="tel:+447711804361"> 0044 7711804361</a>
      </p>
      <hr></hr>
      <h3>Badger Farm Community Centre</h3>
      <p>2 Badger Farm Road Winchester SO22 4QB</p>
    </div>
  );
};

export default ContactPage;
