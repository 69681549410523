import React, { useState, useEffect } from "react";
import {
  getUserSuccesses,
  getTotalUserFailures,
  getTotalUserHints,
} from "../integrations/APIClient";
import StatisticsChart from "./StatisticsChart";
import UserSuccesses from "./UserSuccesses";
import UserFailures from "./UserFailures";
import UserHints from "./UserHints";
import MultiAxisLineChart from "./MultiAxisLineChart";
import PuzzleRatingsTimeSeries from "./PuzzleRatingsTimeSeries";

const Stats = () => {
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [hintCount, setHintCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define static data for the MultiAxisLineChart
  const successDataOverTime = [10, 20, 30, 40, 50];
  const failureDataOverTime = [5, 15, 25, 35, 45];
  const hintDataOverTime = [2, 4, 6, 8, 10];

  useEffect(() => {
    Promise.all([
      getUserSuccesses(),
      getTotalUserFailures(),
      getTotalUserHints(),
    ])
      .then(([successData, failureData, hintData]) => {
        setSuccessCount(successData.totalSuccessCount || 0);
        setFailureCount(failureData.totalFailureCount || 0);
        setHintCount(hintData.totalHintsCount || 0);
      })
      .catch((err) => {
        setError(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return;

  return (
    <div className="stats col">
      <UserSuccesses count={successCount} />
      <UserFailures count={failureCount} />
      <UserHints count={hintCount} />
      <StatisticsChart data={[successCount, hintCount, failureCount]} />
      {/* <MultiAxisLineChart successData={200} failureData={778} hintData={55} /> */}
    </div>
  );
};

export default Stats;
