import React, { useState, useEffect } from "react";
import { getTotalUserFailures } from "../integrations/APIClient"; // Adjust the path accordingly

const UserFailures = () => {
  const [failureCount, setFailureCount] = useState(null); // Store the failure count
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getTotalUserFailures() // Updated function name
      .then((data) => {
        setFailureCount(data.totalFailureCount); // Update to use totalFailureCount
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []); // Empty dependency array, as category is no longer a dependency

  if (isLoading) return <span>Loading...</span>;
  if (error)
    return (
      <span>
        <h2>
          {failureCount}
          <img
            src="/idea4.webp"
            alt="Descriptive text"
            className=" medal img-fluid iconess  "
          />
        </h2>
      </span>
    );

  return (
    <span>
      {failureCount !== null ? (
        <span>
          {" "}
          <h2>
            {failureCount}{" "}
            <img
              src="/idea4.webp"
              alt="Descriptive text"
              className=" medal img-fluid iconess  "
            />
          </h2>
        </span> // Display total failure count
      ) : (
        <span>No failures recorded.</span>
      )}
    </span>
  );
};

export default UserFailures;
