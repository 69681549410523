import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChess } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import StripeBuyButton from "./StripeBuyButton";
import CheckoutForm from "./CheckoutForm";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const handleRegisterClick = () => {
    // Redirect to the registration page
    window.location.href = "/EncorageLogin"; // Adjust the URL as needed
  };
  return (
    <Container className="landing-page ">
      <header className="text-center my-4 col">
        <div className="landing-page">
          <header className="header">
            <StripeBuyButton />
            <h1>Welcome to Chesshood: Your AI-Driven Chess Trainer</h1>
            <p>Learn Chess Faster with AI Guidance!</p>
          </header>

          <section className="main-content">
            <h2>Why Choose Chesshood?</h2>
            <ul>
              <li>
                <strong>Tailored Training:</strong> Our AI analyzes millions of
                chess positions from platforms like lichess.org, categorizing
                and rating puzzles to provide you with the most effective
                learning material.
              </li>

              <Button
                className="register-button btn btn-dark btn-lg btn-block"
                onClick={handleRegisterClick}
                variant="dark"
                href="/EncorageLogin"
              >
                <FontAwesomeIcon icon={faChess} /> AI Training
              </Button>
              <li>
                <strong>In-Depth Analysis:</strong> Understand your strengths
                and weaknesses through detailed player results and targeted
                recommendations.
              </li>
              <li>
                <strong>Quick Improvement:</strong> Navigate the complex world
                of chess with our guided approach, ensuring you don't get lost
                in the myriad of possibilities.
              </li>
            </ul>

            <h2>Our Chess Activities and Events</h2>
            <ul>
              <li>
                <strong>Chess Training Programs:</strong> Led by the Vice
                President of HCA and an experienced chess trainer, we offer
                one-to-one lessons, club training sessions, and more.
              </li>
              <li>
                <strong>Tournaments:</strong> Join beautifully organized
                tournaments by HJCA for junior players in Hampshire and ECF
                tournaments for adults. Enhance your ELO FIDE and ECF ratings
                while enjoying competitive play.
              </li>
              <li>
                <strong>Community and Growth:</strong> Participate in various
                chess events, engage with fellow enthusiasts, and grow together
                in a vibrant chess community.
              </li>
            </ul>

            <h2>Explore More with Chesshood</h2>
            <ul>
              <li>
                <strong>Chess Psychology:</strong> Delve into our articles on
                the psychological aspects of chess to sharpen your mental game.
              </li>
              <li>
                <strong>Improve with Us:</strong> Our site is dedicated to
                helping you improve your chess skills swiftly. With AI-driven
                tools and expert guidance, you’re on the fast track to mastering
                chess.
              </li>
            </ul>
          </section>
        </div>
      </header>
      <section className="">
        <Row className="md-3">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={4}
            className=" col col-landing bg  m-3"
          >
            <div className="activity ">
              <img
                src="/chesscamp.webp"
                alt="chess club"
                className="img-landing"
              />
              <h2>Chess Clubs</h2>
              <h4>What's a day like at a chess club?</h4>
              <p>
                At chess clubs in Winchester, Southampton Central, and
                Southampton Shirley, children play chess with friends. The coach
                provides tips on moves and strategies, and games are often
                recorded and analyzed. Beginners learn the basics, while
                advanced players receive training for tournaments, focusing on
                motifs, strategy, and tactics.
              </p>
              <p>
                <a href="#">Read more</a>
              </p>
            </div>
          </Col>{" "}
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={4}
            className=" col col-landing m-3 bg-light-landing "
          >
            <div className="activity ">
              <img src="/A.png" alt="chess club" className="img-landing" />
              <h2>Online Chess</h2>
              <h4>Personalized Online Chess Training</h4>
              <p>
                Our online chess sessions on Lichess.org and Zoom are
                personalized for each student. We focus on technical skills and
                the psychological aspects crucial in chess. The coach emphasizes
                careful observation and tactics, helping students learn from
                mistakes and avoid blunders. We also use AI for training
                positions and puzzles. Your child is welcome to join us and
                develop both their chess and logical thinking skills.
              </p>
              <p>
                <a href="#">Learn more</a>
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={4}
            className="col col-landing m-3"
          >
            <div className="activity">
              <img
                src="/classic.jpg"
                alt="collaboration"
                className="img-landing"
              />
              <h2>Collaborate with Us</h2>
              <h4>Join Our Chess Innovation Journey</h4>
              <p>
                As a passionate chess enthusiast and educator, I’m launching{" "}
                <strong>ChessHood.com</strong>, an online platform dedicated to
                enhancing chess skills and ratings through AI and advanced
                tools. Our mission is to integrate with Lichess.org to provide
                rapid improvement and personalized coaching. I'm seeking a
                collaborator who shares my vision of transforming chess
                education through technology and innovation.
              </p>
              <p className="contact">chesshampshire@gmail.com</p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={4}
            className=" col col-landing m-3 bg-light-landing"
          >
            <div className="activity ">
              <img
                src="/chesscamp.webp"
                alt="chess club"
                className="img-landing"
              />{" "}
              <h2>Presential Chess Lesson</h2>
              <p>
                Join our in-person chess clubs for hands-on training and
                face-to-face matches. Connect with other chess players in your
                community.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={4}
            className=" col col-landing m-3 "
          >
            <div className="activity ">
              <img
                src="/chesscamp.webp"
                alt="chess club"
                className="img-landing"
              />{" "}
              <h2>
                <Link to="/Calendar">Chess Tournaments</Link>
              </h2>
              <p>
                Compete in local and regional chess tournaments to test your
                skills and gain competitive experience. Check out our calendar
                for upcoming events.
                <br></br>
                <Link to="/Calendar">Chess Events</Link>
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={4}
            className=" col col-landing m-3 bg-light-landing"
          >
            <div className="activity ">
              <img
                src="/chesscamp.webp"
                alt="chess club"
                className="img-landing"
              />
              <h2>Other Events</h2>
              <p>
                We also host various other events to promote chess and build a
                thriving chess community. Stay tuned for our upcoming events!
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="about mt-5 col ">
        <h2 className="text-center mb-4 ">About Us</h2>
        <p>
          Our mission is to grow the chess community and help children aged 5 to
          13 improve their skills and enjoy the game. Whether you're a beginner
          or an advanced player, we have something for everyone.
        </p>
      </section>
      <section className="contact mb-5 mt-4 col ">
        <h2 className="text-center mb-4">Contact Us</h2>
        <p>
          If you have any questions or would like to get involved, feel free to
          reach out to us:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:chesshampshire@gmail.com">chesshampshire@gmail.com</a>
        </p>
        <p>
          Phone: <a href="tel:00447711804361">0044 7711804361</a>
        </p>
      </section>
    </Container>
  );
};

export default LandingPage;
