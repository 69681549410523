// src/PrintPuzzle.js
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Chessground from "@react-chess/chessground";
import { Chess } from "chess.js";

import { useReactToPrint } from "react-to-print";

const PrintPuzzle = () => {
  const { puzzleid } = useParams();
  const [puzzle, setPuzzle] = useState(null);
  const [config, setConfig] = useState({});
  const chessRef = useRef(new Chess());
  const componentRef = useRef();

  useEffect(() => {
    const fetchPuzzle = async () => {
      try {
        const response = await axios.get(
          `http://localhost:7071/api/puzzles/${puzzleid}?category=endgame&rating_from=0&rating_to=700`
        );
        const puzzleData = response.data;
        setPuzzle(puzzleData);
        chessRef.current.load(puzzleData.fenPosition);
        setConfig({
          fen: puzzleData.fenPosition,
          orientation:
            puzzleData.fenPosition.split(" ")[1] === "w" ? "black" : "white",
          movable: {
            free: false,
          },
        });
      } catch (error) {
        console.error("Error fetching puzzle data", error);
      }
    };

    fetchPuzzle();
  }, [puzzleid]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Puzzle-${puzzleid}`,
    onAfterPrint: () => console.log("Print success"),
  });

  if (!puzzle) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button onClick={handlePrint}>Print this out!</button>
      <div className="print-container" ref={componentRef}>
        <h1>Puzzle ID: {puzzle._id}</h1>
        <div className="board">
          <Chessground config={config} />
        </div>
        <div className="details">
          <p>Category: {puzzle.category}</p>
          <p>Rating: {puzzle.rating}</p>
        </div>
      </div>
    </div>
  );
};

export default PrintPuzzle;
