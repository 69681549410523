// PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: 20/10/2024</p>

      <p>
        This Privacy Policy explains how we collect, use, disclose, and protect
        your personal information when you visit our website. By using our
        website, you agree to the terms outlined in this policy.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We collect personal information that you provide to us directly when you
        use our website, such as your name, email address, and any other details
        you provide when contacting us or signing up for our services.
      </p>
      <p>
        We also collect certain information automatically, including your IP
        address, browser type, and pages you visit on our website. This
        information is collected through cookies and similar tracking
        technologies.
      </p>

      <h2>How We Use Your Information</h2>
      <p>We use your personal information for the following purposes:</p>
      <ul>
        <li>To provide and maintain our services</li>
        <li>To respond to your inquiries and requests</li>
        <li>
          To analyze and improve our website's performance and user experience
        </li>
        <li>To send you promotional materials and updates (if you opt-in)</li>
      </ul>

      <h2>How We Share Your Information</h2>
      <p>
        We do not sell or rent your personal information to third parties. We
        may share your information with trusted third-party service providers to
        perform certain functions, such as website analytics, payment
        processing, or marketing services.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        Our website may include links to third-party websites and services. We
        are not responsible for the privacy practices of these third parties. We
        encourage you to review the privacy policies of any external sites you
        visit.
      </p>

      <h2>Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access, correct, or delete your personal information</li>
        <li>Opt out of marketing communications</li>
        <li>Request that we limit the processing of your data</li>
        <li>Request a copy of your personal data</li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at{" "}
        <a href="mailto:chesshood@chesshood.com">chesshood@chesshood.com</a>.
      </p>

      <h2>Security</h2>
      <p>
        We take appropriate technical and organizational measures to protect
        your personal information from unauthorized access, disclosure, or loss.
        However, please note that no method of transmission over the internet or
        method of electronic storage is 100% secure.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and the date of the last update will be indicated
        at the top of the policy. We encourage you to review this Privacy Policy
        regularly.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at <a href="mailto:chesshood@chesshood.com">chesshood@chesshood.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
