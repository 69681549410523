import CardIcon from "../images/credit-card.svg";
import ProductImage from "../images/product-image.jpg";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};
const Checkout = () => {
  const item = {
    price: "price_1P4WojEqx1V56brjykSTZZMk",
    quantity: 1,
  };
  const checkoutOptions = {
    lineItems: [item],
    mode: "subscription",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`, // Corrected typo here
  };
  const rediretToCheckout = async () => {
    console.log("Redirecting to checkout");
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    if (error) {
      console.log("esto es error: ", error);
    }
  };

  return (
    <div className="checkout col">
      <h1 className="susccheck">Chess AI Trainer</h1>
      <h2 className="susccheck">
        Starting at only £4.99 for our startup offer
      </h2>
      <i>
        For cancellations or any other inquiries, please contact
        chesshampshire@gmail.com
      </i>
      <br />
      <i>We will respond within a few days.</i>

      <p className="checkout-title">
        Your guide to improving your chess skills
      </p>
      <p className="checkout-description">
        Learn how to play chess like a grandmaster with our AI-powered chess.
      </p>
      <h1 className="checkout-price">Subscription </h1>
      <h1 className="checkout-price">£4.99 / month</h1>

      <img
        className="checkout-product-image"
        src={ProductImage}
        alt="Product"
      />
      <button className="checkout-button" onClick={rediretToCheckout}>
        <div className="grey-circle">
          <div className="purple-circle">
            <img className="icon" src={CardIcon} alt="credit-card-icon" />
          </div>
        </div>
        <div className="text-container">
          <p className="text">Suscribe</p>
        </div>
      </button>
    </div>
  );
};

export default Checkout;
