import React from "react";

import { createRoot } from "react-dom/client";

import registerServiceWorker from "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

import "./css/board.css"; //shold last one
import "./sass/style.scss";
import "./sass/default.scss";

import "./sass/iphone13.scss";
import "./sass/pc.scss";
import "./sass/styleportrait.scss";
import "./sass/portraitall.scss";

import "./sass/ipad.scss";
import "./sass/ipadlandscape.scss";

import "./sass/responsive.scss";
import "./sass/samsungfold.scss";
import "./sass/samsungfoldportraitopened.scss";
import App from "./App.js";
import { BrowserRouter } from "react-router-dom";

import { getUser } from "./integrations/APIClient.js";

import { createContext, useState, useEffect } from "react";

import { validate } from "./integrations/user.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { UserContextProvider } from "./contexts/UserContext.js";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserContextProvider>
  </React.StrictMode>
);
registerServiceWorker();
