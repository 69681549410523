import React, { createContext, useState, useEffect } from "react";
import { getUser } from "../integrations/APIClient.js";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser()
      .then((data) => {
        console.log("Fetched user data:", data);
        setUser(data);
      })
      .catch(() => {
        console.log("Failed to fetch user data");
        setUser(null);
      });
  }, []);

  useEffect(() => {
    console.log("UserContextProvider user value:", user);
  }, [user]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
