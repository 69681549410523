import React, { useState } from "react";
import { Button, Row, Col, Alert } from "react-bootstrap";
import ChessMoveIndicator from "../components/ChessMoveIndicator";

const InfoPuzzle = ({
  user,
  puzzle,
  config,
  nextPuzzle,
  puzzleFinished,
  repeat,
  hint,
  showHint,
  fen,
}) => {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDescription, setShowDescription] = useState(false); // New state for managing description visibility

  const toggleAlert = () => {
    setShowAlert(!showAlert); // Toggles the visibility of the alert
  };

  const alertDescription = () => {
    setShowDescription(!showDescription); // Toggle visibility to show the description
  };

  // Ensure user and puzzle are defined
  const currentUser = user || {};
  const currentPuzzle = (puzzle && puzzle.current) || {};

  return (
    <div className="cardhood infohood">
      {currentUser.subscription_valid_to &&
        new Date(currentUser.subscription_valid_to * 1000).getTime() <
          Date.now() && (
          <div className="alert alert-success">
            Subscription is valid until{" "}
            {new Date(
              currentUser.subscription_valid_to * 1000
            ).toLocaleDateString()}
            .{" "}
          </div>
        )}
      <div>
        <h4 className="ratings ">
          <Row className="ratingscol">
            <Col>
              <img
                src="/medal.webp"
                alt="Descriptive text"
                className="iconess"
              />
              <span className="h2">{currentUser.rating || "points "}</span>
            </Col>
            <Col>
              <img
                src="/puzzle.png"
                alt="Descriptive text"
                className="iconess"
              />
              <span className="h2">{currentPuzzle.rating || "points "}</span>
            </Col>
          </Row>
        </h4>
      </div>
      <div className="controlbottons">
        <Button
          onClick={hint}
          type="button"
          variant="warning"
          className="btn btn-lg btn-block btn control-btn"
        >
          Hint
        </Button>
        <Button
          onClick={repeat}
          type="button"
          variant="success"
          className="btn btn-lg btn-block btn control-btn"
        >
          Repeat
        </Button>
        <Button
          onClick={nextPuzzle}
          type="button"
          disabled={!puzzleFinished}
          variant="danger"
          className="btn btn-lg btn-block btn control-btn"
        >
          Next
        </Button>
      </div>
      <div className="categoriespuzzles">
        <Button
          variant="primary"
          size="lg"
          className="my-2 custom-large-button"
          onClick={toggleAlert}
        >
          Categories
        </Button>
        <Button
          variant="secondary"
          size="lg"
          className="my-2 custom-large-button"
          onClick={alertDescription}
        >
          Description
        </Button>
      </div>
      {showAlert && (
        <Alert
          variant="success"
          className="alertcategoriespuzzle"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <div className="puzzlesrating">
            <Button
              variant="primary"
              size="sm"
              href="/Profile"
              className="buttonscat"
            >
              Total Rating: {currentUser.rating || "points "}
            </Button>

            {currentPuzzle.category ? (
              currentPuzzle.category.split(" ").map((el, index) => (
                <div key={index}>
                  <Button
                    variant="secondary"
                    size="sm"
                    href="/Profile"
                    className="buttonscat"
                  >
                    {currentUser.category_rating &&
                    currentUser.category_rating[el]
                      ? `${el}: ${currentUser.category_rating[el]}`
                      : `${el}: points `}
                  </Button>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </Alert>
      )}
      {showDescription && (
        <div className="info col">
          <p>
            {currentPuzzle.description
              ? currentPuzzle.description
              : "Try to find the best move. Take your time. Remember to think about all the possibilities."}
          </p>
        </div>
      )}
      <ChessMoveIndicator config={config} />
    </div>
  );
};

export default InfoPuzzle;
