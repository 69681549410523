// SuccessFailMessage.js
import swal from "sweetalert";

// Function to show success message
export function showSuccess(jsonData, successMessage, failOrsuccess) {
  if (successMessage) {
    console.log(successMessage);
  }

  let data;

  // Attempt to parse the JSON data first hhhh
  try {
    data = JSON.parse(jsonData);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    swal("Error", "Provided data is not valid JSON.", "error");
    return;
  }

  // Check if the parsed data is an object
  if (typeof data !== "object" || data === null) {
    console.error("Parsed data is not an object or is null.");
    swal("Error", "Parsed data is not an object or is null.", "error");
    return;
  }

  // Check if the user object is present
  if (!data.user) {
    console.error("Parsed data does not contain a user object.");
    swal("Error", "Parsed data does not contain a user object.", "error");
    return;
  }

  // Check if the _id object is present within user
  if (!data.user._id) {
    console.error("User object does not contain an _id object.");
    swal("Error", "User object does not contain an _id object.", "error");
    return;
  }

  // Check if the $oid string is present within _id
  if (!data.user._id.$oid) {
    console.error("User _id object does not contain a $oid string.");
    swal("Error", "User _id object does not contain a $oid string.", "error");
    return;
  }

  // Check if the rating is present within user
  if (typeof data.user.rating === "undefined") {
    console.error("User object does not contain a rating.");
    swal("Error", "User object does not contain a rating.", "error");
    return;
  }

  // Check if the category_rating object is present within user
  if (!data.user.category_rating) {
    console.error("User object does not contain category ratings.");
    swal("Error", "User object does not contain category ratings.", "error");
    return;
  }

  // Check if the addedOrRestRating is present within data
  if (typeof data.addedOrRestRating === "undefined") {
    console.error("Data does not contain an addedOrRestRating value.");
    swal("Error", "Data does not contain an addedOrRestRating value.", "error");
    return;
  }

  // Now we can safely assume that all properties exist and construct the HTML content
  const userId = data.user._id.$oid;

  const sortedCategoryRatings = Object.entries(data.user.category_rating).sort(
    (a, b) => b[1] - a[1]
  );

  // Generate the HTML for the sorted ratings
  const ratingsHtml = sortedCategoryRatings

    .map(
      ([category, rating]) =>
        `
        <tr><td>${category
          .replace(/([A-Z])/g, " $1")
          .trim()}</td><td>${rating}</td></tr>`
    )
    .join("");

  // Construct the HTML content for the SweetAlert
  const htmlContent = `
  <div style="text-align: left;" class="SwalSuccess">
    <h2 class="${
      failOrsuccess == 0 ? "failMessageAfterSolve" : "successMessageAfterSolve"
    } ">${successMessage}</h2>
    <p class="ratingChange">
  ${
    data.addedOrRestRating == 0
      ? "You have already solved this puzzle. Points are awarded or deducted only once for each puzzle."
      : `Rating Change: ${data.addedOrRestRating > 0 ? "+" : ""}${
          data.addedOrRestRating
        }`
  }
</p>

    <h2 class="userRatingSuccess">User Rating: ${data.user.rating}</h2>
    <h4 class="categoryRatingsgSuccess">Category Ratings:</h4>
    <div class = "categoriesSuccess Categories-statistics" style="max-height: 300px; overflow-y: auto; overflow-x: hidden;">
      <table style="margin: 0 auto; width: 100%;" class="alltableSuccess">
        <tbody>${ratingsHtml}</tbody>
      </table>
    </div>
  </div>
`;

  // Display the SweetAlert with the constructed HTML
  swal({
    title: failOrsuccess ? "Error!" : "Success!",
    content: {
      element: "div",
      attributes: {
        innerHTML: htmlContent,
      },
    },

    icon: failOrsuccess ? "error" : "success",
    button: "Close",
  });
}

export function showError(errorMessage) {
  swal("Error", errorMessage, "error");
}

export function showHintMessage(jsonData) {
  let data;
  try {
    data = JSON.parse(jsonData);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    swal("Error", "Provided data is not valid JSON.", "error");
    return;
  }

  // Ensure data contains the required properties for showing a hint
  if (typeof data.addedOrRestRating === "undefined") {
    console.error("Data does not contain an addedOrRestRating value.");
    swal("Error", "Data does not contain an addedOrRestRating value.", "error");
    return;
  }

  // Construct the HTML content for the hint message
  const htmlContent = `
    <div style="text-align: left;">
      <p>Hint used</p>
      <p>This move will be considered as a draw for ELO calculation. Your rating adjustment is: ${
        data.addedOrRestRating < 0 ? "" : "+"
      }${data.addedOrRestRating}</p>
      
    <Hint
      showHint={showHint}
      after_pgn={after_pgn}
      fen={fen}
      config={config}
      orientation={config.orientation}
    />
    </div>
  `;

  // Display the hint using SweetAlert
  swal({
    title: "Hint",
    content: {
      element: "div",
      attributes: {
        innerHTML: htmlContent,
      },
    },
    icon: "info",
    button: "Close",
  });
}
