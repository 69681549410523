import React from "react";

const ChessClub = () => {
  return (
    <div className="chess-club col">
      <h1>Junior Chess Club - Southampton</h1>
      <h2>Chess Activities on Saturday</h2>
      <div className="details">
        <p>
          <strong>Date and Time:</strong> Every Saturday, 1:00 PM - 2:30 PM
        </p>
        <p>
          <strong>Location:</strong> 11 Portland Street, Southampton, SO14 7EB,
          UK
        </p>
      </div>
      <div className="about">
        <h3>About This Event</h3>
        <p>
          Join us for weekly chess activities every Saturday from 1:00 PM to
          2:30 PM. Whether you're a beginner learning the basics or an advanced
          player honing your strategies, these sessions offer an opportunity for
          players of all levels to improve their skills and enjoy the game of
          chess.
        </p>
      </div>
      <div className="schedule">
        <h3>Schedule</h3>
        <ul>
          <li>1:00 PM - Warm-up games</li>
          <li>1:15 PM - Strategy session</li>
          <li>2 PM - Practice matches</li>
          <li>2:15 PM - Group discussion and analysis</li>
          <li>2:30 PM - End of session</li>
        </ul>
      </div>
      <div className="location">
        <h3>Location</h3>
        <p>
          The activities are held at 11 Portland Street, Southampton, SO14 7EB,
          UK. It's a great venue that provides a comfortable environment for
          learning and playing chess.
        </p>
      </div>
      <div className="contact">
        <h3>Contact</h3>
        <p>
          For more information, please contact us at{" "}
          <a href="mailto:chesshampshire@gmail.com">chesshampshire@gmail.com</a>
          .
        </p>
      </div>
      <div className="register">
        <a
          href="https://www.liberilaeti.co.uk/booking-calendar/chess-club-eng?location=91f94f72-d9ab-4d12-bce3-7b921bb7ef15&referral=service_list_widget"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="register-button">Register Here</button>
        </a>
      </div>
      <div className="image">
        <img src="/chesscamp.jpg" alt="Chess Club" />
      </div>
    </div>
  );
};

export default ChessClub;
