import React, { useState, useEffect } from "react";
import Chessground from "@react-chess/chessground";
import { Chess } from "chess.js";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";

const AfterPgnEdit = ({ initialFen }) => {
  // Accept initialFen as a prop
  console.log(initialFen);
  const [chess] = useState(new Chess(initialFen || "start")); // Initialize chess.js instance with initialFen
  const [fen, setFen] = useState(initialFen || "start"); // Use initialFen for initial state
  const [orientation, setOrientation] = useState("white");
  const [pgn, setPgn] = useState("");

  useEffect(() => {
    chess.load(fen); // Load the initial or new FEN whenever it changes
    setFen(chess.fen());
    setPgn(chess.pgn({ max_width: 5, newline_char: " " }));
  }, [initialFen]); // Depend on initialFen to reload chessboard state

  const onMove = (from, to) => {
    chess.move({ from, to });
    setFen(chess.fen());
    setPgn(chess.pgn({ max_width: 5, newline_char: " " }));
  };

  const flipOrientation = () => {
    setOrientation(orientation === "white" ? "black" : "white");
  };

  return (
    <div className="afterpgnedit">
      {console.log("fen", initialFen)}
      <h2>Setup Success moves </h2>
      <Chessground
        turnColor={chess.turn()}
        orientation={orientation}
        fen="rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2"
        onMove={onMove}
        movable={{
          free: false,
          color: "both",
          events: {
            after: onMove,
          },
        }}
        draggable={{
          showGhost: true,
        }}
      />
      <button onClick={flipOrientation}>Flip Board</button>
      <div>
        <h2>PGN</h2>
        <p>{pgn}</p>
      </div>
    </div>
  );
};

export default AfterPgnEdit;
