// Categories component
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Chessground from "@react-chess/chessground";
import { Chess } from "chess.js";
import swal from "sweetalert";
import { Container, Row, Col, Button } from "react-bootstrap";

import { getUser, getPuzzle } from "../integrations/APIClient";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";
import "chessground/assets/chessground.cburnett.css";

function Categories() {
  const { id: puzzleOrCategoryId } = useParams(); // Can be a puzzle ID or a category name
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [puzzles, setPuzzles] = useState([]);
  const [currentPuzzleIndex, setCurrentPuzzleIndex] = useState(0);
  const [game] = useState(new Chess());

  useEffect(() => {
    const loadUserAndPuzzle = async () => {
      const userData = await getUser();
      setUser(userData);

      // Attempt to load either a specific puzzle or a category of puzzles
      getPuzzle(undefined, puzzleOrCategoryId).then((data) => {
        // Check if data is an array (category) or a single object (specific puzzle)
        if (Array.isArray(data)) {
          setPuzzles(data);
        } else {
          setPuzzles([data]); // Convert single puzzle into an array for consistency
        }
      });
    };

    loadUserAndPuzzle();
  }, [puzzleOrCategoryId]);

  useEffect(() => {
    if (puzzles.length > 0 && currentPuzzleIndex < puzzles.length) {
      game.load(puzzles[currentPuzzleIndex].fenPosition);
    }
  }, [currentPuzzleIndex, puzzles, game]);

  // Functionality to change categories or puzzles could be added here

  return (
    <Container>
      <Row>
        <Col>
          <Chessground config={{ fen: game.fen() }} />
        </Col>
      </Row>
      {/* Additional UI for navigating puzzles or categories */}
    </Container>
  );
}

export default Categories;
