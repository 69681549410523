import React from "react";

const LichessTeamBattleInfo = () => {
  return (
    <div className="event-details col ">
      <h1>Monthly Lichess Team Battle 2024</h1>
      <p>Organized by John Upham</p>
      <div className="event-metadata">
        <strong>Location:</strong> Lichess.org
      </div>

      <h2>About This Event</h2>
      <p>
        The Monthly Team Battle on the Lichess Arena will take place on Monday,
      </p>

      <h2>Registration</h2>
      <p>
        Please register for this ASAP! Registration is free on{" "}
        <a href="https://lichess.org" target="_blank" rel="noopener noreferrer">
          Lichess.org
        </a>
        . After registering, please email John Upham at{" "}
        <a href="mailto:john.upham@zen.co.uk">john.upham@zen.co.uk</a> to be
        added to the team.
      </p>
    </div>
  );
};

export default LichessTeamBattleInfo;
