import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";

import { Row, Col } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import Chessground from "@react-chess/chessground";
import { Chess, SQUARES } from "chess.js";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";

import { getPuzzle, savePuzzle } from "../integrations/APIClient";

const startGame = new Chess();
const pgnGame = new Chess();

function toDests(chess) {
  const dests = new Map();
  SQUARES.forEach((s) => {
    const ms = chess.moves({ square: s, verbose: true });
    if (ms.length)
      dests.set(
        s,
        ms.map((m) => m.to)
      );
  });
  return dests;
}

function toColor(chess) {
  return chess.turn() === "w" ? "white" : "black";
}

export default function Edit2() {
  const puzzleID = useParams().id;

  const [puzzle, setPuzzle] = useState();

  const startConfig = {
    fen: puzzle?.fenPosition,
    events: {
      move: (orig, dest, metadata) => {
        const piece = startGame.get(orig);
        startGame.remove(orig);
        startGame.put(piece, dest);
        setPuzzle({ ...puzzle, fenPosition: startGame.fen(), after_pgn: "" });
        pgnGame.load(startGame.fen());
      },
    },
  };

  const pgnConfig = {
    fen: pgnGame.fen(),
    turnColor: toColor(pgnGame),
    movable: {
      color: toColor(pgnGame),
      dests: toDests(pgnGame),
      events: {
        after: (orig, dest, metadata) => {
          try {
            pgnGame.move({ from: orig, to: dest });
            const newPgn = puzzle.after_pgn.split(" ").filter((e) => e);
            newPgn.push(orig + dest);
            setPuzzle({ ...puzzle, after_pgn: newPgn.join(" ") });
          } catch (err) {
            setPuzzle({ ...puzzle });
          }
        },
      },
    },
  };

  useEffect(() => {
    if (puzzleID) {
      getPuzzle(puzzleID).then((data) => {
        startGame.load(data.fenPosition);
        pgnGame.load(data.fenPosition);
        setPuzzle(data);
      });
    }
  }, []);
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <div>
            <Chessground config={pgnConfig} contained={true} />
          </div>

          <Stack direction="horizontal" gap={2}>
            {puzzle?.after_pgn
              .split(" ")
              .filter((e) => e)
              .map((e, i) => (
                <Button
                  key={i}
                  variant={
                    (i + (puzzle?.fenPosition.split(" ")[1] === "w")) % 2
                      ? "light"
                      : "dark"
                  }
                  onClick={() => {
                    console.log(i, e);
                  }}
                >
                  {e}
                </Button>
              ))}
          </Stack>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="starting-fen">Starting FEN</InputGroup.Text>
            <Form.Control
              value={puzzle ? puzzle?.fenPosition : ""}
              onChange={(e) => {
                try {
                  startGame.load(e.target.value);
                  pgnGame.load(e.target.value);
                  setPuzzle({ ...puzzle, fenPosition: e.target.value });
                } catch (err) {
                  setPuzzle({ ...puzzle });
                }
              }}
              aria-label="Small"
              aria-describedby="starting-fen"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="after_pgn">after_pgn</InputGroup.Text>
            <Form.Control
              value={puzzle && puzzle.after_pgn ? puzzle.after_pgn : ""}
              onChange={(e) => {
                setPuzzle({ ...puzzle, after_pgn: e.target.value });
              }}
              aria-label="Small"
              aria-describedby="after_pgn"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="description">Description</InputGroup.Text>
            <Form.Control
              value={puzzle ? puzzle.description : ""}
              onChange={(e) => {
                setPuzzle({ ...puzzle, description: e.target.value });
              }}
              aria-label="Small"
              aria-describedby="description"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="categories">Chess Categories </InputGroup.Text>
            <Form.Control
              value={puzzle ? puzzle.category : ""}
              onChange={(e) => {
                setPuzzle({ ...puzzle, category: e.target.value });
              }}
              aria-label="Small"
              aria-describedby="categories"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="success">Success</InputGroup.Text>
            <Form.Control
              value={puzzle ? puzzle.success_message : ""}
              onChange={(e) => {
                setPuzzle({ ...puzzle, success_message: e.target.value });
              }}
              aria-label="Small"
              aria-describedby="success"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="failure">Failure</InputGroup.Text>
            <Form.Control
              value={puzzle ? puzzle.fail_message : ""}
              onChange={(e) => {
                setPuzzle({ ...puzzle, fail_message: e.target.value });
              }}
              aria-label="Small"
              aria-describedby="failure"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="rating">Rating</InputGroup.Text>
            <Form.Control
              value={puzzle ? puzzle.rating : ""}
              onChange={(e) => {
                setPuzzle({ ...puzzle, rating: parseInt(e.target.value) });
              }}
              aria-label="Small"
              aria-describedby="rating"
            />
          </InputGroup>

          <Button
            variant="primary"
            onClick={() => {
              savePuzzle(puzzleID, puzzle);
            }}
          >
            Update Puzzle
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              savePuzzle("new", puzzle).then((data) => {
                if (data.id) {
                  window.location.replace("/edit2/" + data.id);
                }
              });
            }}
          >
            Save as new
          </Button>
          <div>
            <Chessground config={startConfig} contained={true} />
          </div>
        </Col>
      </Row>
    </>
  );
}
