import React from "react";
import { Col, Row } from "react-bootstrap";

const ModalCustom = ({
  period,
  day,
  time,
  countday,
  dailyPrice,
  monthlyPrice,
}) => {
  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          {/* Information to display in the modal */}
          <h2>Daily fee</h2>
          <h3>{`£${dailyPrice} / ${day} session , ${time}`}</h3>
          <h2>{period}</h2>
          <h3>{`£${monthlyPrice} / ${countday}  ${day} session , ${time}`}</h3>
          Write to us at{" "}
          <a href="mailto: chesshampshire@gmail.com">
            chesshampshire@gmail.com
          </a>{" "}
        </Col>
      </Row>
      <Row>
        <Col>
          <img
            src="/adamcamp.webp"
            alt="Descriptive text"
            className="  img-landing"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalCustom;
