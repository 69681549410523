import React from "react";
import { useEffect } from "react";

const Calendar = () => {
  useEffect(() => {
    // Select all elements with the class name 'bubble'
    const bubbles = document.getElementsByClassName("bubble");

    // Check if elements exist
    if (bubbles.length > 0) {
      // Loop through all elements and change the width
      for (let bubble of bubbles) {
        bubble.style.width = "100px";
        // To ensure the style is overwritten, you can use setProperty
        bubble.style.setProperty("width", "100px", "important");
      }
    }
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div>
      <h1>ChessHood Calendar</h1>
      <div className="calendar-wrapper">
        <iframe
          src="https://calendar.google.com/calendar/embed?src=chesshood%40chesshood.com&ctz=Europe%2FLondon"
          className="calendar"
          title="ChessHood Calendar"
        ></iframe>
      </div>
    </div>
  );
};

export default Calendar;
