// CookiePolicy.js
import React from "react";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <h1>Cookie Policy</h1>
      <p>Last updated: 05/07/2024</p>

      <p>
        This Cookie Policy explains what cookies are, how we use them, the types
        of cookies we use, and how you can manage your cookie preferences. By
        using our website, you consent to the use of cookies as outlined in this
        policy.
      </p>

      <h2>What are cookies?</h2>
      <p>
        Cookies are small text files that are placed on your computer or mobile
        device when you visit a website. They are widely used to make websites
        work more efficiently and to provide information to the owners of the
        site.
      </p>

      <h2>How we use cookies</h2>
      <p>
        We use cookies to improve your experience on our website, including:
      </p>
      <ul>
        <li>Understanding how you use our website</li>
        <li>Remembering your preferences and settings</li>
        <li>Providing personalized content and ads</li>
        <li>Analyzing our website traffic and performance</li>
      </ul>

      <h2>Types of cookies we use</h2>
      <h3>Necessary Cookies</h3>
      <p>
        These cookies are essential for the website to function properly. They
        enable basic functions like page navigation and access to secure areas
        of the website.
      </p>

      <h3>Preferences Cookies</h3>
      <p>
        These cookies allow the website to remember choices you make and provide
        enhanced, more personal features.
      </p>

      <h3>Statistics Cookies</h3>
      <p>
        These cookies help us understand how visitors interact with our website
        by collecting and reporting information anonymously. We use Google
        Analytics for this purpose.
      </p>

      <h3>Marketing Cookies</h3>
      <p>
        These cookies are used to track visitors across websites. The intention
        is to display ads that are relevant and engaging for the individual
        user.
      </p>

      <h2>Google Analytics</h2>
      <p>
        We use Google Analytics to collect information about how visitors use
        our website. Google Analytics uses cookies to collect information such
        as the number of visitors to the site, the pages they visit, and the
        time they spend on our site. This information helps us improve our
        website and provide a better user experience.
      </p>
      <p>
        For more information on how Google Analytics collects and processes
        data, you can visit{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google's Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://www.google.com/analytics/terms/us.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics Terms of Service
        </a>
        .
      </p>

      <h2>Managing cookies</h2>
      <p>
        You can control and manage cookies in various ways. Please be aware that
        removing or blocking cookies may impact your user experience and parts
        of our website may no longer be fully functional.
      </p>

      <h3>Browser settings</h3>
      <p>
        Most browsers allow you to manage your cookie preferences through their
        settings. You can set your browser to block or alert you about these
        cookies, but some parts of the site may not work as intended.
      </p>

      <h3>Opt-out</h3>
      <p>
        You can opt-out of Google Analytics by installing the Google Analytics
        opt-out browser add-on, available{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <h3>Consent banner</h3>
      <p>
        When you first visit our website, you will see a cookie consent banner
        that allows you to accept or reject the use of cookies. You can change
        your cookie preferences at any time by clicking on the "Cookie Settings"
        link in the footer of our website.
      </p>
    </div>
  );
};

export default CookiePolicy;
